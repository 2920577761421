import {
	navigate as reachNavigate,
	NavigateOptions,
} from '@reach/router';

export type LocationState = {
	/** Path we are navigating from */
	from?: string;
	/** Path to redirect to after action is completed */
	redirectTo?: string;
	/** Used by RouteNotFound for nested route 404 handling */
	notFound?: boolean;
	/** Used by useResetScroll to automatically scroll new routes to top */
	scrolled?: boolean;
} | undefined;

export const navigate = (to: string, options?: NavigateOptions<NonNullable<LocationState>>) => (
	reachNavigate(to, options)
);
