import React from 'react';
import { Link } from '@reach/router';

import './Header.css';

const Header = () => (
	<header className="Header">
		<nav>
			<Link to="/">
				<div
					className="logo"
					role="img"
					aria-label="Area 23 logo"
				/>
				<p className="logoText">AREA 23</p>
			</Link>
		</nav>
	</header>
);

export default Header;
