import { useEffect } from 'react';
import { useLocation } from '@reach/router';

import { LocationState, navigate } from '../helpers/navigation';

export const useResetScroll = () => {
	const location = useLocation();
	const { pathname } = location;
	const state = location.state as LocationState;
	useEffect(
		() => {
			if (!state?.scrolled) {
				(async () => {
					await navigate(
						pathname,
						{
							state: {
								...state,
								scrolled: true,
							},
							replace: true,
						},
					);
					window.scrollTo(0, 0);
				})();
			}
		},
		[pathname, state],
	);
};
