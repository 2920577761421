import React, { FunctionComponent } from 'react';
import { RouteComponentProps, Router } from '@reach/router';

import { LocationState } from '../helpers/navigation';
import Header from '../components/Header';
import Footer from '../components/Footer';
import NotFound from '../components/NotFound';
import Home from './Home';
import RouteNotFound from './RouteNotFound';
import './AppRoot.css';

interface Props extends RouteComponentProps {}

const AppRoot: FunctionComponent<Props> = ({ location }) => {
	const notFound = (location?.state as LocationState)?.notFound ?? false;
	return (
		<div className="AppRoot">
			<Header />
			<main>
				{notFound ? (
					<NotFound />
				) : (
					<Router>
						<Home path="/" />
						<RouteNotFound default />
					</Router>
				)}
			</main>
			<Footer />
		</div>
	);
};

export default AppRoot;
