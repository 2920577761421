import React from 'react';
// import { Link } from '@reach/router';

import './Footer.css';

const Footer = () => (
	<footer className="Footer">
		{/* <nav>
			<Link to="/somewhere">Somewhere</Link>
		</nav> */}
		<p>&copy;1997-2021 Area 23</p>
	</footer>
);

export default Footer;
