import React, {
	FunctionComponent,
} from 'react';
import { RouteComponentProps } from '@reach/router';

import { useResetScroll } from '../helpers/useResetScroll';
import './Home.css';

interface Props extends RouteComponentProps {}

const Home: FunctionComponent<Props> = () => {
	useResetScroll();
	return (
		<article className="Home">
			{/* <header>
				<h1>Hello</h1>
			</header>
			<section className="intro">
				<header>
					<h1>Hi</h1>
				</header>
				<p>
					How are ya
				</p>
			</section> */}
			<div
				className="logo"
				role="img"
				aria-label="Area 23 logo"
			/>
		</article>
	);
};

export default Home;
